define('quizzes-addon/adapters/usage-report', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    saveEventNamespace: '/usagereport/events/write',

    readEventNamespace: '/usagereport/events/read',

    USAGE_REPORT_TOKEN_KEY: 'usagereport.token',

    USAGE_REPORT_URL_KEY: 'usagereport.url',

    /**
     * Saves an event by sending a POST request with the event data.
     *
     * @param {Object} eventData - The data of the event to be saved.
     * @returns {Promise} The promise object representing the result of the AJAX request.
     */
    saveEvent: function saveEvent(eventData) {
      var adapter = this;
      var url = localStorage.getItem(this.USAGE_REPORT_URL_KEY) + adapter.get('saveEventNamespace');
      var options = {
        type: 'POST',
        contentType: 'application/json',
        headers: adapter.defineHeaders(),
        data: JSON.stringify(eventData)
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Reads the like and dislike status for a given user and material.
     *
     * @param {string} userId - The ID of the user.
     * @param {string} contentId - The ID of the content.
     * @returns {Promise} - A promise that resolves with the like and dislike status.
     */
    readLikeAndDislike: function readLikeAndDislike(userId, contentId) {
      var adapter = this;
      var url = localStorage.getItem(this.USAGE_REPORT_URL_KEY) + adapter.get('readEventNamespace') + ('?contentId=' + contentId + '&userId=' + userId + '&productCode=CN');
      var options = {
        type: 'GET',
        contentType: 'application/json',
        headers: adapter.defineHeaders()
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Defines the headers for the AJAX request.
     *
     * @returns {Object} The headers object containing the session reference.
     */
    defineHeaders: function defineHeaders() {
      return {
        'x-session-ref': localStorage.getItem(this.USAGE_REPORT_TOKEN_KEY) || ''
      };
    }
  });
});