define('gooru-web/controllers/facets-search',['exports','ember','gooru-web/models/content/bookmark','gooru-web/mixins/modal','gooru-web/config/config','gooru-web/utils/endpoint-config','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebModelsContentBookmark,_gooruWebMixinsModal,_gooruWebConfigConfig,_gooruWebUtilsEndpointConfig,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsTenantSettingsMixin['default'],_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/bookmark
   */bookmarkService:_ember['default'].inject.service('api-sdk/bookmark'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), /**
   * @type {Controller} Application controller
   */appController:_ember['default'].inject.controller('application'),session:_ember['default'].inject.service(), /**
   * @property {Service} profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @requires service:api-sdk/course
   */courseService:_ember['default'].inject.service('api-sdk/course'), // ----------------------------------------------
// Attributes
classNames:['facets-search'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array} searchResults
   */searchResults:null, /**
   * @property {Boolean} isRemixableContent
   */isRemixableContent:_ember['default'].computed('contentSource',function(){var contentSource=this.get('contentSource');return contentSource === _gooruWebConfigConfig.SEARCH_CONTEXT.GOORU_CATALOG || contentSource === _gooruWebConfigConfig.SEARCH_CONTEXT.LIBRARY || contentSource === _gooruWebConfigConfig.SEARCH_CONTEXT.CAP_CONTENT;}),contentSource:_ember['default'].computed('facetRequestPayload',function(){var requestPayload=this.get('facetRequestPayload') || {};var contentSource=requestPayload['content-source'];return contentSource;}),facetRequestPayload:_ember['default'].computed('appController.facetPayload',function(){return this.get('appController.facetPayload');}),isDeepLink:false,searchFilter:null,maxLimit:20, /**
   * Indicates if the teacher is seeing the card
   * @property {boolean}
   */isTeacher:_ember['default'].computed.equal('session.role','teacher'), /**
   * Indicates if the student is seeing the card
   * @property {boolean}
   */isStudent:_ember['default'].computed.equal('session.role','student'), /**
   * Indicates if bookmark action is disabled
   * @property {boolean}
   */disabledBookmark:_ember['default'].computed('isTeacher','session.isAnonymous',function(){return this.get('session.isAnonymous') || this.get('isTeacher');}),init:function init(){var component=this;component._super.apply(component,arguments);component.set('offlineActivityModel',{courseId:null,unitId:null,lessonId:'new',associateLesson:false,isIndependentOA:true});component.set('isDeepLink',component.get('isDeepLink') === 'true');}, /**
   * @property {Class[]} Active class with and without course
   */activeClasses:_ember['default'].computed('appController.myClasses.classes.[]',function(){var classes=this.get('appController.myClasses');return classes?classes.getTeacherActiveClasses(this.get('session.userId')):[];}), // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on the play icon
openContentPlayer:function openContentPlayer(assessment){var component=this;var previewContentType=assessment.format || (assessment.isExternalAssessment?'assessment-external':'assessment');component.set('previewContent',assessment);component.set('previewContentType',previewContentType);component.set('isShowContentPreview',true);}, //Action triggered when click play icon
openCollectionContentPlayer:function openCollectionContentPlayer(collection){var component=this;var type=collection.format || 'collection';component.set('previewContent',collection);component.set('previewContentType',type);component.set('isShowContentPreview',true);}, /**
     * On card edit collection button click
     * @param {Collection} collection
     */editCollection:function editCollection(collection){this.transitionToRoute(collection && collection.format === 'collection-external'?'content.external-collections.edit':'content.collections.edit',collection.id,{queryParams:{isLibraryContent:true,isPreviewReferrer:false,editing:false}});}, /**
     * Triggers the refresh of user classes
     */updateClass:function updateClass(){this.send('updateUserClasses');}, /**
     * Action triggered to bookmark a course
     * @param {Course} course
     */onBookmarkCourse:function onBookmarkCourse(_ref,showType){var _this=this;var title=_ref.title;var id=_ref.id;var bookmark=_gooruWebModelsContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:title,contentId:id,contentType:_gooruWebConfigConfig.CONTENT_TYPES.COURSE});this.createBookmark(bookmark).then(function(){return _this.notifyBookmarkSuccess(bookmark,showType);});}, /**
     * Edit course action, when clicking Play at the course card
     * @param {Content/Course}
     */playIndependentContent:function playIndependentContent(_ref2){var _this2=this;var title=_ref2.title;var id=_ref2.id;var collectionType=_ref2.collectionType;var isCourse=!collectionType;var bookmark=_gooruWebModelsContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:title,contentId:id,contentType:isCourse?_gooruWebConfigConfig.CONTENT_TYPES.COURSE:collectionType});return this.createBookmark(bookmark).then(function(){if(isCourse){_this2.get('router').transitionTo('student.independent',id);}else {var queryParams={role:_gooruWebConfigConfig.ROLES.STUDENT,source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.INDEPENDENT_ACTIVITY,isIframeMode:true};_this2.get('router').transitionTo('player',id,{queryParams:queryParams});}});}, /**
     * Action triggered to bookmark a collection or assessment
     * @param {Collection/Assessment} content
     */onBookmarkContent:function onBookmarkContent(_ref3,showType){var _this3=this;var title=_ref3.title;var id=_ref3.id;var collectionType=_ref3.collectionType;var bookmark=_gooruWebModelsContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:title,contentId:id,contentType:collectionType});this.createBookmark(bookmark).then(function(){return _this3.notifyBookmarkSuccess(bookmark,showType);});}, /**
     * Edit course action, when clicking Edit at the course card
     * @param {Content/Course}
     */editCourse:function editCourse(course){var queryParams={userId:course.get('ownerId'),isLibraryContent:true,editing:false};this.transitionToRoute('content.courses.edit',course.get('id'),{queryParams:queryParams});}, /**
     * Edit course action, when clicking Play at the course card
     * @param {Content/Course}
     */playCourse:function playCourse(course){var queryParams={sourceType:this.get('type')};if(this.get('productId')){queryParams.productId = this.get('productId');}else if(this.get('libraryId')){queryParams.libraryId = this.get('libraryId');}this.transitionToRoute('content.courses.play',course.id,{queryParams:queryParams});}, /**
     * On card edit assessment button click
     * @param {Assessment} assessment
     */editAssessment:function editAssessment(assessment){this.transitionToRoute(assessment && assessment.format === 'assessment-external'?'content.external-assessments.edit':'content.assessments.edit',assessment.get('id'),{queryParams:{editingContent:true,isLibraryContent:true,isPreviewReferrer:false,editing:false}});}, /**
     * Action triggered when click preview button
     */onPreviewContent:function onPreviewContent(previewContent,previewContentType){var component=this;component.set('previewContent',previewContent);component.set('previewContentType',previewContentType);component.set('isShowContentPreview',true);}, /**
     * On card play question button click
     * @param {Question} question
     */playQuestion:function playQuestion(question){this.transitionToRoute('content.questions.play',question.get('id'));}, /**
     * On card edit question button click
     * @param {Question} question
     */editQuestion:function editQuestion(question){this.transitionToRoute('content.questions.edit',question.get('id'),{queryParams:{isLibraryContent:true,editing:false}});}, /**
     * Edit rubric
     */editRubric:function editRubric(resource){this.transitionToRoute('content.rubric.edit',resource.get('id'));}, /**
     * On card edit resource button click
     * @param {Resource} resource
     */editResource:function editResource(resource){this.transitionToRoute('content.resources.edit',resource.get('id'),{queryParams:{isLibraryContent:true}});}, //Action triggered On card play resource button click
openResourceContentPlayer:function openResourceContentPlayer(resource){var component=this;var type=resource.format || 'resource';component.set('previewContent',resource);component.set('previewContentType',type);resource.set('userId',this.get('session.userId'));var contentId=resource.get('id');var isDeepLink=resource.get('isDeepLink') || false;var contentType=component.get('previewContentType');var playerURL=(0,_gooruWebUtilsEndpointConfig.getEndpointUrl)();playerURL += '/player/resource/' + contentId + '?isDeepLink=' + isDeepLink;component.set('playerContent',component.get('previewContent'));var content=component.get('playerContent');content.set('format',contentType);component.set('playerUrl',playerURL);component.set('isOpenResourcePlayer',true);},onShowModal:function onShowModal(type){if(!type){this.transitionToRoute('content.activity.edit',this.get('offlineActivityModel'));return;}var className=type === 'content.modals.gru-resource-new'?'gru-resource-new':'';this.send('showModal',type,null,null,className);}, //Action triggered when click on OA preview
onShowOfflineActivityPreview:function onShowOfflineActivityPreview(offlineActivity){var component=this;component.set('previewContent',offlineActivity);component.set('isShowOfflineActivityPreview',true);}, // Action Help to identity to play or preview the content
onPlayFacets:function onPlayFacets(content){content = _ember['default'].Object.create(content);content.set('id',content.id || content.gooruOid);content.set('format',content.format || content.contentFormat);if(content.format === _gooruWebConfigConfig.CONTENT_TYPES.COURSE){if(this.get('contentSource') !== _gooruWebConfigConfig.SEARCH_CONTEXT.MY_CONTENT){this.send('onPreviewCourse',content);}else {this.send('playCourse',content);}}else if(content.format.indexOf(_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT) !== -1){this.send('openContentPlayer',content);}else if(content.format.indexOf(_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION) !== -1){this.send('openCollectionContentPlayer',content);}else if(content.format === _gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY){this.send('onShowOfflineActivityPreview',content);}else if(content.format === _gooruWebConfigConfig.CONTENT_TYPES.QUESTION){this.send('playQuestion',content);}else if(content.format === _gooruWebConfigConfig.CONTENT_TYPES.RESOURCE){this.send('openResourceContentPlayer',content);}}, //Action triggered when click on edit
onEditActivity:function onEditActivity(offlineActivity){var controller=this;var contentId=offlineActivity.id;controller.transitionToRoute('content.activity.edit',contentId,{queryParams:{editingContent:true,isLibraryContent:true}});},onAddContent:function onAddContent(selectedResources){selectedResources.forEach(function(resource){resource.format = resource.type || resource.contentFormat || resource.format;});var selectedObjects={};var format=selectedResources[0].format;selectedObjects.format = format;selectedObjects.contentFormat = format;selectedObjects.resources = selectedResources;if(['question','resource'].indexOf(format) === -1){this.send('addToClassroom',selectedObjects);}else {this.send('addToCollection',selectedObjects);}},onRequestPayload:function onRequestPayload(payloadData){this.set('appController.facetPayload',_ember['default'].Object.create(payloadData));}, /**
     * Action triggered to add to classroom or daily class activities
     */addToClassroom:function addToClassroom(contents){var component=this;var content=_ember['default'].Object.create(contents.resources[0]);var classRoomList=component.get('activeClasses');var isCourse=content.format === 'course';content.set('subject',content.subject_bucket || content.subject);if(isCourse){(function(){var subjectBucket=content.get('subject');var subjectCode=null;var frameworkCode=null;if(subjectBucket){var splitCode=subjectBucket.split('.');subjectCode = subjectBucket;if(splitCode.length === 3){frameworkCode = splitCode[0];subjectCode = splitCode[1] + '.' + splitCode[2];}}classRoomList = classRoomList.filter(function(validClass){var preference=validClass.get('preference');var settings=validClass.get('setting');return (preference && preference.get('subject') === subjectCode && preference.get('framework') === frameworkCode && (settings['course.premium'] && content.get('version') === 'premium' || !settings['course.premium'] && !content.get('version')) && !validClass.get('courseId') || !settings['course.premium']) && !validClass.courseId;});})();} // component.addStudentCountToClasses();
var model=_ember['default'].Object.create({classroomList:classRoomList,classActivity:!isCourse,content:content});if(isCourse){model.set('callback',{success:function success(){component.send('updateClass');}});}component.send('showModal','content.modals.gru-add-to-classroom',model,null,'add-to');}, /**
     * Action triggered to add to collection
     */addToCollection:function addToCollection(content){var component=this;var isQuestion=content.contentFormat === 'question';if(component.get('session.isAnonymous')){component.send('showModal','content.modals.gru-login-prompt');}else {var assessmentsPromise=_ember['default'].RSVP.resolve(null);if(isQuestion){assessmentsPromise = component.get('profileService').readAssessments(component.get('session.userId'));}assessmentsPromise.then(function(assessments){return component.get('profileService').readCollections(component.get('session.userId')).then(function(collections){return {content:content,collections:collections,assessments:assessments};});}).then(function(model){return component.send('showModal','content.modals.gru-add-to-collection',model,null,'add-to');});}}, /**
     * Action triggered to preview the content
     * @param content
     */onPreviewCourse:function onPreviewCourse(content){var _this4=this;var component=this;if(!component.get('isDeepLink')){(function(){var isTeacher=_this4.get('isTeacher');var isStudent=_this4.get('isStudent');var contentId=content.get('id');var model=_ember['default'].Object.create({content:content,isTeacher:isTeacher,isStudent:isStudent,disabledBookmark:_this4.get('disabledBookmark')});component.get('courseService').fetchById(contentId).then(function(course){model.set('content',course);model.set('remixCourse',function(){return component.remixCourse(course);}); // model.set('content.taxonomy', course.taxonomy);
model.set('bookmarkCourse',function(){return component.send('bookmarkContent',content,false);});model.set('playCourse',function(){return component.send('playIndependent',content,false);});}).then(function(){component.send('showModal','gru-preview-course',model);});})();}}}, // -------------------------------------------------------------------------
// Methods
/**
   * Send bookmark info to BE for creation
   * @param bookmark
   */createBookmark:function createBookmark(bookmark){return this.get('bookmarkService').createBookmark(bookmark);}, /**
   * Show notification on bookmark success
   * @param bookmark
   * @param showType
   */notifyBookmarkSuccess:function notifyBookmarkSuccess(bookmark,showType){this.get('notifications').setOptions({positionClass:'toast-top-full-width',toastClass:'gooru-toast',timeOut:10000});var successMsg=showType?this.get('i18n').t('common.bookmarked-content-success',{contentType:bookmark.get('contentType')}):this.get('i18n').t('common.bookmarked-success');var independentLearningURL=this.get('router').generate('student-independent-learning');var buttonText=this.get('i18n').t('common.take-me-there');this.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + independentLearningURL + '">' + buttonText + '</a>');},remixCourse:function remixCourse(content){if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {var remixModel={content:content};this.send('showModal','content.modals.gru-course-remix',remixModel);}}});});