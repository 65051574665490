define('quizzes-addon/services/quizzes/usage-report', ['exports', 'ember', 'quizzes-addon/adapters/usage-report'], function (exports, _ember, _quizzesAddonAdaptersUsageReport) {
  exports['default'] = _ember['default'].Service.extend({
    usageReportAdapter: null,

    /**
     * Initializes the service and sets the usage report adapter.
     */
    init: function init() {
      this._super.apply(this, arguments);
      this.set('usageReportAdapter', _quizzesAddonAdaptersUsageReport['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    /**
     * Saves an event by delegating to the usage report adapter.
     *
     * @param {Object} eventData - The data of the event to be saved.
     * @returns {Promise} The promise object representing the result of the save operation.
     */
    saveEvent: function saveEvent(eventData) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('usageReportAdapter').saveEvent(eventData).then(resolve, reject);
      });
    },

    readLikeAndDislike: function readLikeAndDislike(userId, contentId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('usageReportAdapter').readLikeAndDislike(userId, contentId).then(resolve, reject);
      });
    }
  });
});